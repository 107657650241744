import { __decorate } from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { TagType, OperateType, ShareType, SourceType, } from '../../../../typings/organization/label-manage';
import UpdateLabelBase from './update-label-base';
let UpdateLabel = class UpdateLabel extends UpdateLabelBase {
    async comfirm() {
        super.comfirm(ShareType.Sys);
    }
    cancel() {
        super.cancel();
    }
    async onValueChange(v) {
        if (v) {
            this.modalTitle = (this.operateType === OperateType.Add ? '添加' : '编辑') + '标签';
            const labeldata = this.labelDetail;
            this.loading = true;
            if (this.operateType === OperateType.Edit) {
                // 编辑标签
                const detail = await this.getDetail(labeldata.id);
                this.form.name = detail.name;
                this.form.group = detail.parentId;
                this.isSelectedOrg = true;
                this.isSelectedGroup = true;
            }
            else {
                if (labeldata) {
                    this.isSelectedOrg = true;
                    this.isSelectedGroup = true;
                    this.form.group = labeldata.id;
                }
            }
            const groupParams = {
                labelType: TagType.Group,
                shareType: ShareType.Sys,
                labelId: '',
                sourceType: SourceType.SelfMaintain,
            };
            this.groupList = await this.getDataList(groupParams);
            this.loading = false;
        }
        else {
            this.form = {
                name: '',
                group: undefined,
            };
            this.groupList = [];
            this.isSelectedOrg = false;
            this.isSelectedGroup = false;
        }
    }
};
__decorate([
    Watch('value')
], UpdateLabel.prototype, "onValueChange", null);
UpdateLabel = __decorate([
    Component({
        name: 'UpdateLabel',
        components: {
            CForm: Form,
            CFormItem: FormItem,
        },
    })
], UpdateLabel);
export default UpdateLabel;
